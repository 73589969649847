import poster from "../assets/poster.png";

import React, { useEffect, useState } from "react";
import "./Mint.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { ethers } from "ethers";

import ABI from "./abi.json";
const PROVIDER = "https://goerli.infura.io/v3/bd3283c53475479792ed8f68c398a0e9";
// "https://mainnet.infura.io/v3/97d9f5fedfa34db7a15d53259ffe34c2";
const CONTRACT = "0x1EeA524becFB4379a970652021e3DAF6Cc24A4de";

export default function Mint() {
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);

  const connect = async () => {
    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    const ct = new ethers.Contract(CONTRACT, ABI, provider);
    setPrice(await ct.Price());
  };
  connect();

  const mint = async () => {
    if (!window.ethereum) {
      toast.error("Wallet not found");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    let m;
    try {
      m = await provider.send("eth_requestAccounts", []);
      m = m[0];
    } catch (e) {
      toast.error(e.message);
      return;
    }

    const signer = provider.getSigner();
    const ct = new ethers.Contract(CONTRACT, ABI, signer);

    if (!(await ct.status())) {
      toast.error("Minting not Active!!");
      return;
    }

    const balance = Number(await provider.getBalance(m));

    if (balance < Number(price) * quantity) {
      toast.error("Insufficient ETH");
      return;
    }
    await toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const tx = await ct.mint(String(quantity), {
            value: String(price * quantity),
          });
          const req = await tx.wait();
        } catch (e) {
          reject(e);
        }
        resolve();
      }),
      {
        pending: "Loading",
        success: "Complete",
        error: "Failed!!",
      }
    );
  };

  return (
    <div className="main fullheight align-items-center justify-content-center d-flex flex-column">
      <div className="container bg align-items-center justify-content-center d-flex">
        <div className="row align-items-center justify-content-center px-md-5">
          <div className="col-lg-7">
            <h6 className="text-white">8700/8700 AZUKIS REMAINING (PHASE 1)</h6>
            <h1 className="text-white">
              <strong>HOW MANY LASER CAT FOR YOU</strong>
            </h1>
            <div className="d-flex flex-wrap">
              <button
                className={`quantityBtn ${quantity == 1 ? "active" : ""}`}
                onClick={() => setQuantity(1)}
              >
                01
              </button>
              <button
                className={`quantityBtn ${quantity == 2 ? "active" : ""}`}
                onClick={() => setQuantity(2)}
              >
                02
              </button>
              <button
                className={`quantityBtn ${quantity == 3 ? "active" : ""}`}
                onClick={() => setQuantity(3)}
              >
                03
              </button>
              <button
                className={`quantityBtn ${quantity == 4 ? "active" : ""}`}
                onClick={() => setQuantity(4)}
              >
                04
              </button>
              <button
                className={`quantityBtn ${quantity == 5 ? "active" : ""}`}
                onClick={() => setQuantity(5)}
              >
                05
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <img src={poster} className="poster d-none d-lg-block w-100 px-3" />
          </div>
        </div>
      </div>
      <div className="container">
        <span className="d-flex flex-wrap justify-content-md-end align-items-end text-white mt-3">
          <small className="px-3 mx-auto mx-md-0">
            <p className="m-0">Price</p>
            <h6>
              <strong>{ethers.utils.formatEther(price)} ETH</strong>
            </h6>
          </small>
          <small className="px-3 mx-auto mx-md-0">
            <p className="m-0">LASER CAT</p>
            <h6>
              <strong>{quantity}x</strong>
            </h6>
          </small>
          <small className="px-3 mx-auto mx-md-0">
            <p className="m-0">Price</p>
            <h6>
              <strong>{quantity * ethers.utils.formatEther(price)} ETH</strong>
            </h6>
          </small>
          <button
            className="mintBtn mt-3 mx-auto mx-md-0"
            onClick={() => mint()}
          >
            MINT NOW
          </button>
        </span>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar theme="colored" />
    </div>
  );
}
