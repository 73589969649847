exports.songsdata = [
    {
        "title": "Omniverse theme",
        "url": "https://azuki-songs.s3.amazonaws.com/f1/runit.mp3"
    },
    {
        "title": "Aris",
        "url": "https://lasercats.io/static/media/LC_RockTheme_ArisII.mp3"
    }

]