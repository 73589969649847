import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import vidTavern77 from "../assets/tavern77_outer.mp4";
import SpriteSheet_Main_Tavern77 from "./Spritesheets/Spritesheet_Main_Tavern77";
import '../styles/tavern77.scss';

const Tavern77_out = () => {
    return (
        <div class = "tavern77_inner-container">
            <Header />
            <div class = "tavern77">
                <div class="video-container">
                    <div class="mainvideo">
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: "inline-block"}}
                            src={vidTavern77}
                            autoPlay
                            loop
                            muted
                        />
                    </div>
                     <div id="tavern77_inner-container" class = "infolink-container">
                        <Link to = "/tavern77">
                            <SpriteSheet_Main_Tavern77 />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tavern77_out;